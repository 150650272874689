<template>
  <!-- Table Container Card -->
  <div>
    <div class="table-list">
      <div style="display: flex; justify-content: space-between" class="mb-1">
        <b-breadcrumb class="breadcrumb-slash mb-1">
          <b-breadcrumb-item :to="{ name: 'dashboardadmin-ecommerce' }">
            ลูกค้าของฉัน
            <!-- Dashboard -->
          </b-breadcrumb-item>
          <b-breadcrumb-item active>
            รายการคำสั่งซื้อ
            <!-- ข้อมูลคำสั่งซื้อ -->
          </b-breadcrumb-item>
        </b-breadcrumb>
        <div></div>
      </div>

      <b-card no-body>
        <b-table
          :items="ItemList"
          :fields="tableColumns"
          responsive
          show-empty
          empty-text="ไม่พบข้อมูล"
          class="position-relative"
        >
        <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>

        </b-table>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BBreadcrumb,
  BBreadcrumbItem,
  BModal,
  VBModal,
  BFormCheckbox,
  BFormTimepicker,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { ref, onUnmounted } from "@vue/composition-api";
import store from "@/store";
import useMyCustomerList from "./useMyCustomerList";
import Ripple from "vue-ripple-directive";
import myCustomerModule from "./myCustomerModule";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import axios, { baseURL } from "@axios";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BBreadcrumb,
    BBreadcrumbItem,
    vSelect,
    BModal,
    VBModal,
    ToastificationContent,
    BFormCheckbox,
    flatPickr,
    BFormTimepicker,
  },

  data() {
    return {
      ItemList: [],
      tableColumns: [
        { key: "index", label: "ลำดับ" },
        { key: "productName", label: "สินค้า" },
        {
          key: "customerName",
          label: "ชื่อ - นามสกุล ลูกค้า",
        },
        { key: "customerPhoneNumber", label: "เบอร์โทร" },
       
      ],
    };
  },

  mounted() {
		this.getList();
	},

  methods: {

    showToast(variant, icon, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon,
          text,
          variant,
        },
      });
    },

    getList(){

      const userData = getUserData()

      var pram = {
        userId: userData.userId,
      }

      axios
      .post(`${baseURL}/api/Order/GetAlertList?userId=${userData.userId}`)
      .then((response) => {
            console.log(response.data.data);
            this.ItemList = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          });

    },
  },



};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
